import type { IconClass } from '#core/config/ui'
import type { EffectScope } from 'vue'
import { useDebounceFn, useMouseInElement } from '@vueuse/core'
import { withTrailingSlash } from 'ufo'

export interface NavLink {
  icon?: IconClass
  to?: string
  label?: string
  key?: string
  external?: boolean
  loading?: boolean
  children?: NavLink[]
  isStrict?: boolean
  on?: Record<string, any>
  testId?: string
}

type SlideoverMenuId = 'tools-and-services' | 'forgd-academy' | null
const expanded = ref(false)
const isLoggingOut = ref(false)
const slideoverMenu = ref<SlideoverMenuId>(null)
const runtimeConfig = useRuntimeConfig()

const SLIDEOVER_SWITCH_DELAY_MS = 250
let slideoverScope: EffectScope | null = null
const closeSlideoverOnMouseover = {
  mouseenter: event => handleSlideoverChange({ event, slideover: null }),
}
function handleSlideoverChange(options: { event: any, slideover: SlideoverMenuId }) {
  // closing already closed menu
  if (!options.slideover && !slideoverMenu.value) {
    return
  }

  const open = () => {
    slideoverMenu.value = options.slideover
    nextTick(() => {
      if (slideoverScope) {
        slideoverScope.stop()
      }
      slideoverScope = effectScope()
      slideoverScope!.run(() => {
        const { isOutside: isOutsideMenuItem } = useMouseInElement(options.event.target.parentElement)
        const { isOutside: isOutsideSlideover } = useMouseInElement(document.querySelector('[data-el="AppMenuSlideover"]')!)
        watch(isOutsideMenuItem, (outside) => {
          debounceSlideoverFn(() => {
            if (isOutsideSlideover.value) {
              if (outside) {
                close()
              }
              else {
                open()
              }
            }
          })
        })
        let hasEnteredSlideover = false
        watch(isOutsideSlideover, (outside) => {
          if (!outside) {
            hasEnteredSlideover = true
          }
          if (!hasEnteredSlideover) {
            return
          }
          debounceSlideoverFn(() => {
            if (hasEnteredSlideover && outside) {
              close()
            }
            else {
              open()
            }
          })
        })
      })
    })
  }
  const close = () => {
    if (slideoverScope) {
      slideoverScope.stop()
    }
    slideoverMenu.value = null
  }
  // opening menu for the first time
  if (options.slideover && !slideoverMenu.value) {
    // switch immediately
    open()
    return
  }
  // queuedSlideoverMenu.value = options.slideover
  if (!options.event) {
    // we require an event if we're switching or closing
    return
  }

  debounceSlideoverFn(options.slideover ? open : close)
}

// @ts-expect-error broken upstream types
const debounceSlideoverFn = useDebounceFn<(() => void)>(fn => fn(), SLIDEOVER_SWITCH_DELAY_MS) as
  (fn: () => void) => void

useAcademyNav() // trigger the nav to load before we need it

async function logout() {
  const auth = useAuth()
  isLoggingOut.value = true
  await auth.logout()
  isLoggingOut.value = false
}

const academyNav = ref<NavLink[]>([])

export const topLevelMenu = computed(() => {
  const auth = useAuth()
  return [
    [
      {
        icon: 'i-heroicons-home',
        to: auth.dashboardPath,
        label: 'Home',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-forgd-tools',
        label: 'Tools & Services',
        key: 'tools-and-services',
        on: {
          mouseenter(event) {
            handleSlideoverChange({
              event,
              slideover: 'tools-and-services',
            })
          },
        },
      },
      {
        icon: 'i-heroicons-academic-cap',
        label: 'Forgd Academy',
        key: 'forgd-academy',
        on: {
          mouseenter(event) {
            handleSlideoverChange({
              event,
              slideover: 'forgd-academy',
            })
          },
        },
      },
      {
        label: 'Schedule a Consultation',
        to: runtimeConfig.public.consultationUrl,
        external: true,
        icon: 'i-heroicons-calendar-days',
        on: closeSlideoverOnMouseover,
      },
    ],
    [
      {
        icon: 'i-heroicons-information-circle',
        to: 'https://forgd.com/about',
        label: 'About Forgd',
        external: true,
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-chat-bubble-oval-left-ellipsis',
        to: '/support',
        label: 'Support',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-cog-8-tooth',
        to: '/settings',
        label: 'Account Settings',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-arrow-right-start-on-rectangle',
        label: 'Logout',
        loading: isLoggingOut.value,
        on: {
          click: logout,
          ...closeSlideoverOnMouseover,
        },
        testId: 'logout',
      },
    ],
  ] satisfies NavLink[][]
})

export function useAcademyNav() {
  const res = useAsyncData('navigation', () => fetchContentNavigation('academy'), {
    transform: data => data[0].children,
  })
  watch(res.data, (val) => {
    if (val) {
      academyNav.value = val as NavLink[]
    }
  })
  return res
}

export const preTgeNav: NavLink[] = [
  {
    label: 'Tokenomics & Protocol',
    icon: 'i-heroicons-cube',
    children: [
      { label: 'Token Designer', to: '/token-designer' },
      { label: 'Token Unlocks', to: '/token-unlocks' },
      { label: 'Smart Contract Development', to: '/partner-activity-lead/pre-tge-smart-contract-protocol' },
      { label: 'Token Creation & Mint', to: '/partner-schedule/magna' },
      { label: 'Airdrop & Token Streaming', to: '/partner-schedule/magna' },
      { label: 'Multi-Sig Token Custody', to: '/partner-schedule/squads' },
    ],
  },
  {
    label: 'Finance and Fundraising',
    icon: 'i-heroicons-banknotes',
    children: [
      { label: 'Cashflow Analysis', to: 'https://docs.google.com/spreadsheets/d/13TsO0y64pvv1mAbParQjK7AIIK0FxICc3CtBIQd3NTk/edit?gid=798759478#gid=798759478' },
      { label: 'Growth Capital', to: 'https://docs.google.com/spreadsheets/d/1FWjsPxQALgSIOeXEmuvbRqb-PiWhZtWCvjGyQXegTFY/edit?gid=147303761#gid=147303761' },
      { label: 'Go-to-Market Budgeting', to: 'https://docs.google.com/spreadsheets/d/1P0RhK4QYrTt9cqfCpf46qV1FnpH82N7CtlYkei3ISgU/edit?gid=1931257942#gid=1931257942' },
      { label: 'Public Sale, Launchpad', to: '/partner-schedule/legion' },
    ],
  },
  {
    label: 'Go-to-Market Partnership',
    icon: 'i-heroicons-globe-alt',
    children: [
      { label: 'Listing & Liquidity Academy', to: 'https://docsend.com/view/fexpeu4nxmvt8j72' },
      { label: 'Engage a Market Maker', to: '/engage-a-market-maker' },
      { label: 'Exchange Listing', to: '/exchange-listing' },
      { label: 'Marketing & KOL Support', to: 'https://docs.google.com/spreadsheets/d/1k7pyJe5x24wBnMET428rNqMTaKvPlsMqE1lZlLqeoC0/edit?gid=1326628848#gid=1326628848' },
      { label: 'Launch Checklist', to: 'https://docs.google.com/spreadsheets/d/1zo0zxfqXzvivroHi38jO0wHXLvpmRPO1dc6BRMMsB14/edit?usp=sharing' },
    ],
  },
  {
    label: 'Legal & Compliance',
    icon: 'i-heroicons-scale',
    children: [
      { label: 'Entity formation & incorporation', to: '/partner-schedule/cavenwell' },
      { label: 'External General Counsel', to: '/partner-schedule/cavenwell' },
      { label: 'Legal Opinion on Token', to: '/partner-schedule/cavenwell' },
    ],
  },
]

export const listedTokenNav: NavLink[] = [
  {
    label: 'Analysis & Insights',
    icon: 'i-heroicons-light-bulb',
    children: [
      { label: 'Market Maker Monitoring', to: '/market-maker-monitoring/start' },
      { label: 'Token Unlock Monitoring', to: '/token-unlocks' },
      { label: 'Volume, Liquidity & Price Performance', to: '/partner-activity-lead/volume-liquidity-price-performance' },
    ],
  },
  {
    label: 'Performance Optimization',
    icon: 'i-heroicons-chart-bar-square',
    children: [
      { label: 'Tokenomics & Demand Drivers', to: '/partner-activity-lead/tokenomics-demand-drivers' },
      // { label: 'Demand Driver Optimization', to: '/token-designer' },
      { label: 'AMM²', to: '/partner-activity-lead/amm-2' },
      { label: 'Smart Contract Development', to: '/partner-activity-lead/post-tge-smart-contract-protocol' },
      { label: 'Airdrop & Token Streaming', to: '/partner-schedule/magna' },
    ],
  },
  {
    label: 'Strategic Partnership',
    icon: 'i-heroicons-puzzle-piece',
    children: [
      { label: 'Listing & Liquidity Academy', to: 'https://docsend.com/view/fexpeu4nxmvt8j72' },
      { label: 'Engage a Market Maker', to: '/engage-a-market-maker' },
      { label: 'Exchange Listing', to: '/exchange-listing' },
      { label: 'Marketing & KOL Support', to: 'https://docs.google.com/spreadsheets/d/1k7pyJe5x24wBnMET428rNqMTaKvPlsMqE1lZlLqeoC0/edit?gid=1326628848#gid=1326628848' },
    ],
  },
  {
    label: 'Finance and Fundraising',
    icon: 'i-heroicons-banknotes',
    children: [
      { label: 'Cashflow Analysis', to: 'https://docs.google.com/spreadsheets/d/1xElL1SBH88T1xyxTCpne2qPfJy7vouoDp2UZOzsO18A/edit?gid=798759478#gid=798759478' },
      { label: 'Growth Capital', to: 'https://docs.google.com/spreadsheets/d/1FWjsPxQALgSIOeXEmuvbRqb-PiWhZtWCvjGyQXegTFY/edit?gid=147303761#gid=147303761' },
    ],
  },
  {
    label: 'Legal & Compliance',
    icon: 'i-heroicons-scale',
    children: [
      { label: 'External General Counsel', to: '/partner-schedule/cavenwell' },
      { label: 'Legal Opinion on Token', to: '/partner-schedule/cavenwell' },
    ],
  },
]

function firstChildToIfDir(item: NavLink) {
  if (item._partial && item.children?.[0]) {
    return item.children[0]?._path
  }
  return item._path
}

export const forgdAcademyNav = computed(() => {
  if (!academyNav.value?.length) {
    return []
  }
  return [
    ...academyNav.value.map((item) => {
      return {
        ...item,
        label: item.title,
        to: item._path,
        children: (item.children || []).map(item => ({ ...item, label: item.title, to: firstChildToIfDir(item) })),
      }
    }),
    {
      label: 'Other Readings',
      icon: 'i-heroicons-newspaper',
      children: [
        { label: 'Forgd Products FAQs', to: 'https://forgd.com/faq#tools' },
        { label: 'About Forgd FAQs', to: 'https://forgd.com/faq#about' },
        { label: 'Forgd Blog', to: 'https://forgd.com/blog' },
      ],
    },
  ] satisfies NavLink[]
})

export function useNavState() {
  return {
    slideoverMenu,
    expanded,
  }
}

export function isCurrent({ to, key }: NavLink, strict: boolean = false) {
  const route = useRoute()
  // we just care about the first path segment matching
  const pathSegments = (to || '').split('/').filter(Boolean)
  const currPathSegments = (route.path || '').split('/').filter(Boolean)
  if (!strict) {
    if (withTrailingSlash(currPathSegments[0]) === withTrailingSlash(pathSegments[0])) {
      return true
    }
    // check slideover links
    if (key === 'tools-and-services') {
      return [...preTgeNav.map(m => m.children), ...listedTokenNav.map(m => m.children)].flat().some(item => withTrailingSlash(item.to) === withTrailingSlash(route.path))
    }
    if (key === 'forgd-academy') {
      return route.path.startsWith('/academy')
    }
  }
  // special logic, we match the top-level category which is the first 3 segments
  if (route.path.startsWith('/academy')) {
    const categoryPath = currPathSegments.slice(0, 3).join('/')
    return withTrailingSlash(categoryPath) === withTrailingSlash(pathSegments.slice(0, 3).join('/'))
  }
  // requires full match
  return withTrailingSlash(to) === withTrailingSlash(route.path)
}
